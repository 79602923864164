import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { skills } from '../../assets/skillData'
import './skills.css'


const leftVariant = {
  start: { opacity: 0, y: 300 },
  ends: {
    opacity: 1, y: 0,
    transition: { type: 'spring', damping: 80, stiffness: 200 },
  },

}
const rightVariant = {
  start: { opacity: 0, x: -300 },
  ends: {
    opacity: 1, x: 0,
    transition: { type: 'spring', damping: 80, stiffness: 200 }
  },
}

const Skills = () => {
  return (
    <div className='skills section' id='skills'>
      <AnimatePresence>
        <motion.div className="left-side"
          variants={leftVariant}
          initial='start'
          whileInView='ends'
        >
          <h1 >Web Development</h1>

          {skills.web.map((skill) => {
            return <div className='bar' key={skill.id}>
              <h3>{skill.name} </h3>
              <div className="per" style={{ maxWidth: `${skill.per}%` }}><span className='spanPer'>{skill.per}</span> </div>
            </div>
          })}

        </motion.div>
      </AnimatePresence>
      {/*                                 right side       */}
      <AnimatePresence>
        <motion.div className="right-side"

          variants={rightVariant}
          initial='start'
          whileInView='ends'
        >
          <h1 >Data</h1>

          {skills.data.map((skill) => {
            return <div className='bar' key={skill.id}>
              <h3>{skill.name} </h3>
              <div className="per" style={{ maxWidth: `${skill.per}%` }}><span className='spanPer'>{skill.per}</span> </div>
            </div>
          })}


        </motion.div>
      </AnimatePresence>
    </div>
  )
}

export default Skills
