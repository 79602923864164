import React, { useState, useEffect } from 'react'
import { Link } from 'react-scroll'
import './nav.css'
import ihome from '../../assets/images/home.png'
import iabout from '../../assets/images/idcard.png'
import iskill from '../../assets/images/education.png'
import icontact from '../../assets/images/contact1.png'
import colorIcon from '../../assets/images/color_icon.png'
import { colors } from '../../assets/skillData'


const Nav = () => {
  const [menu, setMenu] = useState(false)
  const [color, setColor] = useState('orange');
  const [showColor, setShowColor] = useState(false);

  const handleMenu = () => {
    setMenu(prvMenu => !prvMenu)
  }

  useEffect(() => {
    document.documentElement.style.setProperty('--primary', color)
    if (window.innerWidth < 1050) {
      setMenu(true)
    }

  }, [color])


  window.addEventListener("scroll", closeColor);
  window.addEventListener("resize", (event) => {
    closeMenu()
    closeColor()
  });


  function closeColor() {
    setShowColor(false)
  }
  function closeMenu(id = 0) {
    setMenu(true);
    const menus = document.querySelectorAll('.menu-item')

    Array.from(menus).forEach(item => {
      if (Number(item.id) === id) {
        item.classList.add('active')
      } else {
        item.classList.remove('active')
      }
    })

  }
  return (
    <nav className='nav-container'>
      <div className="logo">
        <Link smoth to='heros'>
          <span>E</span>skinder
        </Link>
      </div>
      <div className={showColor ? 'color-wraper show' : 'color-wraper'}>
        <div className="color-style">
          {colors.map((color) => {
            return <span className='orange' style={{ backgroundColor: color.code }} onClick={() => setColor(color.code)}></span>

          })}
        </div>

      </div>

      <div className={menu ? 'menu' : 'menu show-menu'}>
        <div className="menu-wraper">
          <Link className='menu-item' id='1' smooth to='heros' onClick={() => closeMenu(1)} ><img className='icons' src={ihome} alt="" /> Home</Link>
          <Link className='menu-item' id='2' smooth to='about' onClick={() => closeMenu(2)} ><img className='icons' src={iabout} alt="" />About</Link>
          <Link className='menu-item' id='3' smooth to='skills' onClick={() => closeMenu(3)} ><img className='icons' src={iskill} alt="" />Skills</Link>
          <Link className='menu-item' id='4' smooth to='contacts' onClick={() => closeMenu(4)} >
            <img className='icons' src={icontact} alt="" />Contact</Link>
        </div>
      </div>
      <div className="humberger">
        <span onClick={handleMenu}>&#9776;</span>
      </div>
      <div className="color-btn">
        <img src={colorIcon} alt="" onClick={() => setShowColor(!showColor)} />
      </div>

    </nav>
  )
}

export default Nav