
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Hero from "./components/heros/Hero";
import Nav from "./components/navmenu/Nav";
import Skills from "./components/skills/Skills";

function App() {
  return (
    <div className="app">
      <Nav />
      <Hero />
      <About />
      <Skills />
      <Contact />
    </div>
  );
}

export default App;
