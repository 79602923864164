
const skills =
{
  web: [{ id: 1, name: 'Html', per: 92 },
  { id: 2, name: 'Css', per: 82 },
  { id: 3, name: 'JavaScript', per: 89 },
  { id: 4, name: 'ReactJs', per: 81 },
  { id: 5, name: 'Web Design', per: 88 },
  { id: 6, name: 'MongoDB', per: 78 }],
  data: [{ id: 7, name: 'Python', per: 89 },
  { id: 8, name: 'MySQL', per: 95 },
  { id: 9, name: 'Web Scraping', per: 85 },
  { id: 10, name: 'Automation Testing', per: 78 },
  { id: 11, name: 'Problem Solving', per: 93 },
  { id: 12, name: 'Fast Learner', per: 96 },]
}


const myInfo = {
  name: 'Eskinder Negash',
  title: 'Front-end developer',
  passion: 'Coding',
  email: 'eskinder@negash.net',
  country: 'United State',
  language: 'English, Amharic',
  availability: 'Available',
  passw: 'password101'
}
const colors = [
  { id: 1, name: 'light green', code: '#00f455' },
  { id: 2, name: 'Green', code: '#3EC70B' },
  { id: 3, name: 'Yellow', code: '#F8DE22' },
  { id: 4, name: 'lightblue', code: '#A076F9' },
  { id: 5, name: 'Purple', code: '#C147E9' },
  { id: 6, name: 'orange', code: '#FFA41B' },

]


export { skills, myInfo, colors }