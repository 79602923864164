import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import myresume from '../../assets/resume.pdf'
import { myInfo } from '../../assets/skillData'
import aboutpic from '../../assets/images/aboutpic.png'
import './about.css'

const leftVariant = {
  start: { opacity: 0, y: 300 },
  ends: {
    opacity: 1, y: 0,
    transition: { type: 'spring', damping: 80, stiffness: 200 },
  },

}
const rightVariant = {
  start: { opacity: 0, x: -300 },
  ends: {
    opacity: 1, x: 0,
    transition: { type: 'spring', damping: 80, stiffness: 200 }
  },
}


const About = () => {

  const downloadSuccess = () => toast.success("  download, Successful !");
  const wrongpassword = () => toast.error("  Wrong Password !");
  const { REACT_APP_PWORD } = process.env

  const resumeDownload = () => {
    const mypass = prompt('Enter the password . . .')
    if (mypass === REACT_APP_PWORD) {
      let newtag = document.createElement('a')
      newtag.href = myresume
      newtag.setAttribute('download', 'eskinder.pdf');
      document.body.appendChild(newtag)
      newtag.click()
      downloadSuccess()
    } else {
      wrongpassword()
    }

  }

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  return (
    <div className='about section' name='about'>
      <AnimatePresence>
        <motion.div className="left-side"
          variants={leftVariant}
          initial='start'
          whileInView='ends'
        >

          <div className="about-left">
            <div className="mypic">
              <img src={aboutpic} alt="me" />

            </div>
            <div className="nameinfo">
              <h2>Eskinder Negash</h2>
              <h3>Front-End</h3>
              <h3>Web Developer</h3>
            </div>
            <div className="hireme-wraper">
              <button onClick={() => resumeDownload()} className="hireme" >More About Me
              </button>
              <span>You need password to see the Resume</span>

              <ToastContainer position="bottom-right" />

            </div>

          </div>

        </motion.div>
      </AnimatePresence>

      {/*                          right side start here           */}
      <AnimatePresence>
        <motion.div className="right-side"

          variants={rightVariant}
          initial='start'
          whileInView='ends'
        >
          <div className="about-info">
            <div className="iaminfo">
              <h1>My name is Eskinder</h1>
              <h3>a Front-End Web Developer</h3>
            </div>
            <div className="aboutdetail">
              <p className='detail'>
                I am self-taught software engineer with strong ability to adopt in both self-starting and collaborative environment while staying focused on achieving high-quality result. Enjoy learning and staying current on bleeding edge technology, system, libraries and frameworks. In the past two years i completed more than 10 different online courses mostly from udemy and other online academies.
              </p>
            </div>
            <div className="infolist">
              <div className="infoleft">
                <h4><span>Name : </span> {myInfo.name}</h4>
                <h4><span>Title : </span> {myInfo.title}</h4>
                <h4><span>Passion : </span> {myInfo.passion}</h4>

              </div>
              <div className="inforight">
                <h4><span>Country : </span> {myInfo.country}</h4>
                <h4><span>Language : </span> {myInfo.language}</h4>
                <h4><span>Availability : </span> {myInfo.availability}</h4>

              </div>
            </div>
          </div>

        </motion.div>
      </AnimatePresence>
    </div>
  )
}

export default About
