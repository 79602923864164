import React, { useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './contact.css'

const leftVariant = {
  start: { opacity: 0, y: 300 },
  ends: {
    opacity: 1, y: 0,
    transition: { type: 'spring', damping: 80, stiffness: 200 },
  },

}
const rightVariant = {
  start: { opacity: 0, x: -300 },
  ends: {
    opacity: 1, x: 0,
    transition: { type: 'spring', damping: 80, stiffness: 200 }
  },
}

const Contact = () => {
  const emailSuccess = () => toast.success("Sent, Successful !");
  const emailfail = () => toast.error("Something went wrong !");
  const form = useRef()
  const { REACT_APP_SERVICE, REACT_APP_TEMPLATE, REACT_APP_PUBLIC } = process.env

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(REACT_APP_SERVICE, REACT_APP_TEMPLATE, form.current, REACT_APP_PUBLIC)

      .then((result) => {
        emailSuccess()

      }, (error) => {
        emailfail()

      });
    console.log(form.current);
    form.current.reset();
  }

  return (
    <div className='contact section' name='contacts'>
      <AnimatePresence>
        <motion.div className="left-side"
          variants={leftVariant}
          initial='start'
          whileInView='ends'
        >
          <h2>Rockville MD</h2>
          <div className="map">
            <iframe className='map-frame' title='address'
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d49574.21272340057!2d-77.17433988682183!3d39.052064408360174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7cc37029cc39f%3A0x1a893108abc63190!2sRockville%2C%20MD%2020852!5e0!3m2!1sen!2sus!4v1689538865042!5m2!1sen!2sus"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </motion.div>
      </AnimatePresence>
      {/*                      right side start here     */}
      <AnimatePresence>
        <motion.div className="right-side"
          variants={rightVariant}
          initial='start'
          whileInView='ends'
        >

          <h2 >Contact Me</h2>
          <div className="emails">
            <form ref={form} className="forms" onSubmit={sendEmail}>
              <div className="name label-box">
                <label htmlFor="name">Name : </label>
                <input placeholder='your name' type="text" name='name' required />
              </div>
              <div className="email label-box">
                <label htmlFor="email">Email : </label>
                <input placeholder='your email' type="text" name="email" required />
              </div>
              <div className="subject label-box">
                <label htmlFor="subject">Subject : </label>
                <input placeholder='subject' type="text" name="subject" required />
              </div>
              <div className="message">
                <h4 className='label-msg'>Messages :</h4>
                <textarea placeholder='message' name="message" id="msg" cols="40" rows="10"></textarea>
                <button type='submit' className="sender">SEND</button>
                <ToastContainer position="top-right" />
              </div>

            </form>
          </div>

        </motion.div>
      </AnimatePresence>
      <div className="footer">
        <p>Created by Eskinder</p>
        <p>Copyright all reserved</p>
        <p>&copy; 2023</p>
      </div>
    </div>
  )
}

export default Contact
