import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'react-scroll'
import './hero.css'
import mypic from '../../assets/images/eskupic.png'

const leftVariant = {
  start: { opacity: 0, y: 300 },
  ends: {
    opacity: 1, y: 0,
    transition: { type: 'spring', damping: 80, stiffness: 200 },
  },

}
const rightVariant = {
  start: { opacity: 0, scale: 0.5 },
  ends: {
    opacity: 1, scale: 1, transition: { type: 'spring', damping: 80, stiffness: 200 }
  },
}

const Hero = () => {


  return (
    <div className='intro section' name='heros'>
      <div className="shape-bg"></div>
      <div className="left-side">
        <AnimatePresence>
          <motion.div
            variants={leftVariant}
            initial='start'
            whileInView='ends'
            viewport={{ once: false }}

            className="left-side-wraper">
            <h2 className='i-intro-name'>Hello, My name is <span className='arrows'>&#8628;</span></h2>
            <h1 className='i-name'><span>E</span>skinder <span>N</span>egash</h1>

            <div className="hero-detail">
              <p>I am Highly motivated self-taught, front-end web developer, who loves
                solving problems, seeking to launch a Career building web
                applications and services. I strongly believe, I would be one of
                the top contributors in your team.
              </p>
            </div>
            <div className="hero-btn">

              <div className="i-title">
                <div className="i-title-wraper">
                  <div className="title-item">Web Developer</div>
                  <div className="title-item">Web Designer</div>
                  <div className="title-item">Software Tester</div>
                </div>
              </div>
              <Link to='about'>
                <button className="hireme">HIRE ME</button>
              </Link>

            </div>
          </motion.div>
        </AnimatePresence>
      </div>
      {/*                                         right side                  */}

      <div className="right-side">
        <AnimatePresence>
          <motion.div
            variants={rightVariant}
            initial='start'
            whileInView='ends'
            className="i-img-wraper">
            <img src={mypic} alt="" className='i-img' />
          </motion.div>
        </AnimatePresence>
      </div>

    </div>
  )
}

export default Hero;